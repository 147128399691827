body {
  background-color: "#fff";

}

.contact-form {
  background: #fff;
  margin-top: 6%;
  margin-bottom: 2%;
  width: 150px;
  font-family: "Catamaran, sans-serif",
}

.custom-quill-editor {
  height: 20em;
  /* Ajusta el tamaño en función del número de filas deseadas */
}

.contact-form .form-control {
  border-radius: 1rem;
}

.contact-image {
  text-align: center;
}

.contact-image img {
  border-radius: 3rem;
  width: 26%;
  margin-top: -1%;
  transform: rotate(0deg);
}

.contact-form form {
  padding: 2%;
}

.contact-form form .row {
  margin-bottom: -1%;
}

.contact-form h3 {
  margin-bottom: 8%;
  margin-top: -10%;
  text-align: center;
  color: #0062cc;
}

.contact-form .btnContact {
  width: 150px;
  border: none;
  border-radius: 1rem;
  padding: 1.5%;
  background: #beb9ba;
  font-weight: 300;
  color: #fff;
  cursor: pointer;
}

.btnContactSubmit {
  width: 20%;
  border-radius: 1rem;
  padding: 1.5%;
  color: #fff;
  background-color: #0085CA;
  border: none;
  cursor: pointer;
}