::selection {
    color: #fff;
    color: var(--second-color);
}

.section-menu {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999999;
}

.top-menu .menu-item {
    font-size: .79em;
}

li {
    margin-left: 1rem;
    list-style-position: inside;
}

.menu-d {
    background-image: url(https://www.huelladeconfianza.com/wp-content/themes/infinity-theme/img/bg.main-menu.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 9999;
}

.menu-d .circle-container {
    position: absolute;
    background-color: #0085ca;
    width: 100%;
    height: 100%;
    overflow: hidden;
    left: 50%;
    -webkit-transform: translateX(-520px);
    transform: translateX(-550px);
}

.menu-d .container {
    background-color: var(--main-color);
    width: 100%;
    /* Ocupa el 100% del ancho de la pantalla */
    padding-right: 15px;
    /* Ajusta según el diseño de tu contenedor */
    padding-left: 15px;
    /* Ajusta según el diseño de tu contenedor */
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
}



.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

.menu-d .main-menu {
    padding-left: 64px;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.menu-d .main-menu .menu-item a {
    font-family: var(--title-font);
    color: #fff;
    font-weight: 800;
    font-size: 1em;
    -webkit-transition: all .2s;
    margin-right: 0em;
    margin-left: 0.4em;
    transition: all .2s;
    text-decoration: none;
    padding: -0.9em;
}

.menu-d .main-menu .sub-menu {
    display: none;
    position: absolute;
    -webkit-transition: all .2s;
    transition: all .2s;
    width: 500px;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -15px;
}

:after,
:before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

*,
:after,
:before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}


.top-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: all .5s;
    transition: all .5s;
    position: relative;
    margin-bottom: 0;
    visibility: visible;
    height: auto;
    -webkit-transition: all 1s;
    transition: all 1s;
    opacity: 1;
}

.menu-d .circle {
    height: 300px;
    width: 300px;
    background: #fff;
    border-radius: 50%;
    -webkit-transform: translate(-20%, -35%);
    transform: translate(-20%, -35%);
    -webkit-transition: all 1.5s;
    transition: all 1.5s;
}

ul {
    list-style: none;
}

hoja de estilo del agente de usuario ul {
    list-style-type: disc;
}

body,
html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: var(--main-font), var(--alternate-font);
    font-size: var(--font-size);
    line-height: var(--line-height);
    color: var(--text-color);
    background-color: var(--bg-modal-color);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

:root {
    --main-font: 'Lato';
    --title-font: 'Catamaran';
    --boton-font: 'Catamaran';
    --alternate-font: sans-serif;
    --font-size: 16px;
    --line-height: 35px;
    --main-color: #0085ca;
    --main-color-transparet: rgba(0, 135, 202, .5);
    --second-color: #003087;
    --green-color: #00bb7e;
    --second-color-transparet: #2774AE80;
    --sidebar-color: #f8fbff;
    --text-color: #333;
    --title-color: #003087;
    --link-color: var(--main-color);
    --link-hover-color: var(--second-color);
    --container-width: 1200px;

    --header-height: 4rem;
}

:root {
    --wp-admin-theme-color: #007cba;
    --wp-admin-theme-color-darker-10: #006ba1;
    --wp-admin-theme-color-darker-20: #005a87;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.top-menu .menu-item:before {
    content: "•";
    color: #8dc8e8;
    margin-right: .1em;
}

*,
:after,
:before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

*,
:after,
:before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

::selection {
    background-color: var(--main-color);
    color: var(--second-color);
}

.top-menu .menu-item:first-child {
    list-style-type: none;
    background: var(--green-color);

    padding: 0 em;
}

.col-2 .btn-pagos {
    background: radial-gradient(#fffdff 5%, #efeff0 25%, #d9dcdd 50%);
    position: absolute;
    top: -72px;
    right: -850px;

    font-size: 14.6px;
    text-align: center;
    width: 130px;
    height: 33px;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
}


.top-menu .menu-item a {
    color: #fff;
    font-family: var(--title-font);
    font-weight: 500;
    padding: 0 1em;
}

.col-2 .btn-clientes {
    background: radial-gradient(#fffdff 5%, #efeff0 25%, #d9dcdd 50%);
    position: relative;
    bottom: -15px;
    font-size: 14.6px;
    text-align: center;
    width: 130px;
    height: 33px;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
}

.navbar-nav .nav-link {
    color: #fff;
}

.dropend .dropdown-toggle {
    color: salmon;
    margin-left: 1em;
    margin-top: 10px;
}

.dropdown-item:hover {
    background-color: lightsalmon;
    color: #f41919;
}

/* Using a class */
.catamaran-font {
    font-family: 'Catamaran', sans-serif;
}

/* Applying it directly to an element */
body {
    font-family: 'Catamaran', sans-serif;
}

.dropdown .dropdown-menu {
    display: none;
    margin-top: 12px;
}

.dropdown:hover>.dropdown-menu,
.dropend:hover>.dropdown-menu {
    display: block;

    background-color: #777;
    /* Puedes ajustar el color gris según tus preferencias */
    color: rgb(11, 0, 0);
}

us,
.dropdown-item:active {
    background-color: #777;
    color: white;
}